<mat-toolbar matDialogTitle class="mat-primary m-0">
  <mat-toolbar-row class="flex">
    <span class="dialog-title flex-1 truncate">Request Information</span>
    <button
      class="close-icon"
      mat-icon-button
      (click)="matDialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="pt-4 max-w-128 max-h-160">
  <mat-stepper #stepper cdk-scrollable labelPosition="bottom">
    <mat-step label="First Step" color="primary" class="overflow-y-auto">
      <div
        class="text-lg tracking-tight text-tilled-primary break-normal w-full pb-8 text-center"
      >
        Requesting information updates the application status to 'Action
        Required' and preserves all existing inputs.
      </div>
      <div
        class="text-lg font-bold tracking-tight text-tilled-primary break-normal w-full pb-2 text-left"
      >
        Select Underwriting Requirements
      </div>
      <div
        class="text-lg tracking-tight text-tilled-secondary break-normal w-full pb-4 text-left"
      >
        Select the written prompts and documents that you need from this
        merchant.
      </div>
      <mat-divider class="w-full pb-4"></mat-divider>

      <form [formGroup]="writtenForm">
        <div class="flex flex-row flex-auto w-full">
          <div class="text-md text-tilled-secondary text-left w-40">
            Written Prompts
          </div>
          <mat-divider class="w-full border-t-2 mt-2"></mat-divider>
        </div>
        <div *ngFor="let type of writtenSubtypes">
          <mat-checkbox class="pt-2" [formControlName]="type.toString()">
            <div class="text-lg tracking-tight text-tilled-primary">
              {{ mapSubtypeToTitle(type) }}
            </div>
          </mat-checkbox>
        </div>
      </form>

      <form [formGroup]="documentForm">
        <div class="flex flex-row flex-auto w-full pt-4">
          <div class="text-md text-tilled-secondary text-left w-50">
            Business Documents
          </div>
          <mat-divider class="w-full border-t-2 mt-2"></mat-divider>
        </div>
        <div *ngFor="let type of documentSubtypes">
          <mat-checkbox class="pt-2" [formControlName]="type.toString()">
            <div class="text-lg tracking-tight text-tilled-primary">
              {{ mapSubtypeToTitle(type) }}
            </div>
          </mat-checkbox>
        </div>
      </form>

      <div class="flex w-full justify-end">
        <!-- Cancel button -->
        <button class="mr-4" mat-button (click)="cancelClicked()">
          Cancel
        </button>
        <!-- Open application button -->
        <button mat-flat-button color="primary" (click)="nextPage()">
          <span>Next</span>
        </button>
      </div>
    </mat-step>

    <mat-step label="Second Step" color="primary" class="overflow-y-auto">
      <div
        class="text-lg tracking-tight text-tilled-primary break-normal w-full pb-8 text-center"
      >
        Requesting information updates the application status to 'Action
        Required' and preserves all existing inputs.
      </div>
      <div
        class="text-lg font-bold tracking-tight text-tilled-primary break-normal w-full pb-2 text-left"
      >
        Add a note (Optional)
      </div>
      <div
        class="text-lg tracking-tight text-tilled-secondary break-normal w-full pb-4 text-left"
      >
        Add a note to each underwriting requirement to provide additional
        context to the merchant about the underwriting requirements. The note
        will be displayed in the email notification to the merchant and in the
        merchant app. Leave blank if no additional context is needed.
      </div>
      <mat-divider class="w-full pb-4"></mat-divider>

      <form
        [formGroup]="writtenRequestForm"
        *ngIf="checkedWrittenSubtypes?.length > 0"
      >
        <div class="flex flex-row flex-auto w-full">
          <div class="text-md text-tilled-secondary text-left w-40">
            Written Prompts
          </div>
          <mat-divider class="w-full border-t-2 mt-2"></mat-divider>
        </div>
        <div *ngFor="let type of checkedWrittenSubtypes">
          <div class="text-lg tracking-tight text-tilled-primary pt-3 pb-1">
            {{ mapSubtypeToTitle(type) }}
          </div>
          <mat-form-field class="w-full">
            <input
              matInput
              [formControlName]="type.toString()"
              maxLength="255"
            />
          </mat-form-field>
        </div>
      </form>

      <form
        [formGroup]="documentRequestForm"
        *ngIf="checkedDocumentSubtypes?.length > 0"
      >
        <div class="flex flex-row flex-auto w-full pt-4">
          <div class="text-md text-tilled-secondary text-left w-50">
            Business Documents
          </div>
          <mat-divider class="w-full border-t-2 mt-2"></mat-divider>
        </div>
        <div *ngFor="let type of checkedDocumentSubtypes">
          <div class="text-lg tracking-tight text-tilled-primary pt-3 pb-1">
            {{ mapSubtypeToTitle(type) }}
          </div>
          <mat-form-field class="w-full">
            <input
              matInput
              [formControlName]="type.toString()"
              maxLength="255"
            />
          </mat-form-field>
        </div>
      </form>

      <div class="flex w-full justify-end">
        <!-- Cancel button -->
        <button class="mr-4" mat-button (click)="backPage()">Back</button>
        <!-- Open application button -->
        <button mat-flat-button color="primary" (click)="submitClicked()">
          <span>Submit Request</span>
        </button>
      </div>
    </mat-step>
    <mat-step label="Second Step" color="primary"> </mat-step>
  </mat-stepper>
</div>
