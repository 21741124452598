import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SnakeCaseSplitPipe } from 'app/core/pipes/snake-case-split.pipe';
import { TimeAgoPipe } from '../core/pipes/time-ago.pipe';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FuseCardModule } from '@fuse/components/card';
import { BankAccountVerificationMethodPipe } from 'app/core/pipes/bank-account-verification-method.pipe';
import { AdminPermissionDirective } from '../core/directives/admin-permission.directive';
import { MinorUnitsToCurrencyPipe } from '../core/pipes/minor-units-to-currency.pipe';
import { MinusSignToParensPipe } from '../core/pipes/minus-sign-to-parens.pipe';
import { PrettyJsonPipe } from '../core/pipes/pretty-json.pipe';
import * as fromComponents from './components';
import { TimelineCardComponent } from './components/cards/timeline-card/timeline-card.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { OpenApplicationDialogComponent } from './components/open-application/open-application-dialog.component';
import { RejectApplicationDialogComponent } from './components/reject-application/reject-application-dialog.component';
import { RejectDocumentDialogComponent } from './components/reject-document/reject-document-dialog.component';
import { RequestInformationDialogComponent } from './components/request-information/request-information-dialog.component';
import { ReviewBankAccountDialogComponent } from './components/review-bank-account/review-bank-account-dialog.component';
import { SubmitApplicationDialogComponent } from './components/submit-application/submit-application-dialog.component';
import { UserListImpersonateComponent } from './components/user-list/impersonate/impersonate.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { VerifyDocumentDialogComponent } from './components/verify-document/verify-document-dialog.component';
import { VerifyUserCheckDialogComponent } from './components/verify-user-check-dialog/verify-user-check-dialog.component';
import { VerifyUserCreateDialogComponent } from './components/verify-user-create-dialog/verify-user-create-dialog.component';
import { ViewDocumentDialogComponent } from './components/view-document-dialog/view-document-dialog.component';

const classesToInclude = [
  TimeAgoPipe,
  SnakeCaseSplitPipe,
  PrettyJsonPipe,
  MinusSignToParensPipe,
  MinorUnitsToCurrencyPipe,
  UserListComponent,
  UserListImpersonateComponent,
  VerifyUserCreateDialogComponent,
  VerifyUserCheckDialogComponent,
  AdminPermissionDirective,
  ViewDocumentDialogComponent,
  TimelineCardComponent,
  ReviewBankAccountDialogComponent,
  OpenApplicationDialogComponent,
  SubmitApplicationDialogComponent,
  BankAccountVerificationMethodPipe,
  RejectApplicationDialogComponent,
  DocumentListComponent,
  RequestInformationDialogComponent,
  RejectDocumentDialogComponent,
  VerifyDocumentDialogComponent,
];

const materialModules = [
  FormsModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatMomentDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  ReactiveFormsModule,
];

@NgModule({
  declarations: [classesToInclude, ...fromComponents.components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FuseCardModule,
    ...materialModules,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...materialModules,
    ...classesToInclude,
    ...fromComponents.components,
  ],
  providers: [CurrencyPipe, ...classesToInclude],
})
export class SharedModule {}
