<div class="w-full">
  <ng-container *ngIf="documentsCount > 0; else noDocuments">
    <!-- Table wrapper -->
    <div class="w-full" cdkScrollable>
      <!-- Table -->
      <table
        class="w-full bg-transparent"
        [ngClass]="{ 'pointer-events-none': isLoading }"
        mat-table
        [multiTemplateDataRows]="false"
        [dataSource]="dataSource"
        [trackBy]="trackByFn"
      >
        <!-- Document subtype -->
        <ng-container matColumnDef="requirement">
          <th
            class="w-fit bg-gray-50 dark:bg-black dark:bg-opacity-5"
            mat-header-cell
            *matHeaderCellDef
          >
            Requirement
          </th>
          <td class="pr-2" mat-cell *matCellDef="let document">
            <span [matTooltip]="document.requestNotes"
              >{{ document.requirement | snakeCaseSplit | titlecase }}
              <mat-icon
                *ngIf="document.requestNotes"
                class="icon-size-4 pt-1"
                [svgIcon]="'heroicons_solid:pencil'"
              ></mat-icon>
            </span>
          </td>
        </ng-container>

        <!-- Document Type -->
        <ng-container matColumnDef="type">
          <th
            class="w-fit bg-gray-50 dark:bg-black dark:bg-opacity-5"
            mat-header-cell
            *matHeaderCellDef
          >
            Type
          </th>
          <td class="pr-2 truncate" mat-cell *matCellDef="let document">
            {{ document.type == 'file' ? 'Document' : 'Written' }}
          </td>
        </ng-container>

        <!-- Requested On -->
        <ng-container matColumnDef="requested_on">
          <th
            class="w-fit bg-gray-50 dark:bg-black dark:bg-opacity-5"
            mat-header-cell
            *matHeaderCellDef
          >
            Requested On
          </th>
          <td class="pr-2" mat-cell *matCellDef="let document">
            <date-tooltip
              [includeTime]="false"
              [date]="document.requested_on"
            ></date-tooltip>
          </td>
        </ng-container>

        <!-- Submitted On -->
        <ng-container matColumnDef="submitted_on">
          <th
            class="w-fit bg-gray-50 dark:bg-black dark:bg-opacity-5"
            mat-header-cell
            *matHeaderCellDef
          >
            Submitted On
          </th>
          <td class="pr-2" mat-cell *matCellDef="let document">
            <date-tooltip
              [includeTime]="false"
              [date]="document.submitted_on"
            ></date-tooltip>
          </td>
        </ng-container>

        <!-- Document Status -->
        <ng-container matColumnDef="status">
          <th
            class="w-fit bg-gray-50 dark:bg-black dark:bg-opacity-5"
            mat-header-cell
            *matHeaderCellDef
          >
            Status
          </th>
          <td class="pr-2" mat-cell *matCellDef="let document">
            <document-status
              [status]="document.status"
              [statusHistory]="document.history"
              [rejectedReason]="document.rejectedReason"
            ></document-status>
          </td>
        </ng-container>

        <!-- View/Download -->
        <ng-container matColumnDef="view_download">
          <th
            class="w-fit bg-gray-50 dark:bg-black dark:bg-opacity-5"
            mat-header-cell
            *matHeaderCellDef
          >
            Actions
          </th>
          <td class="pr-2 verified" mat-cell *matCellDef="let document">
            <button
              *ngIf="document.fileId || document.writtenResponse"
              [permission]="'underwriting:write'"
              (click)="viewFile(document.fileId, document.writtenResponse)"
            >
              View
            </button>
            <button
              class="pl-2"
              *ngIf="document.fileId"
              [permission]="'underwriting:write'"
              (click)="downloadFile(document.fileId)"
            >
              Download
            </button>
          </td>
        </ng-container>

        <!-- Action Menu -->
        <ng-container matColumnDef="actions">
          <th
            class="w-24 pr-8 bg-gray-50 dark:bg-black dark:bg-opacity-5"
            mat-header-cell
            *matHeaderCellDef
          ></th>
          <td
            class="pr-8 absolute right-0 h-full pt-4"
            mat-cell
            *matCellDef="let document"
          >
            <button
              class="ml-auto"
              mat-icon-button
              [matMenuTriggerFor]="documentActionsMenu"
              [disabled]="
                !(
                  document.status === 'submitted' ||
                  document.status === 'requested'
                )
              "
            >
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:dots-vertical'"
              ></mat-icon>
            </button>

            <mat-menu #documentActionsMenu="matMenu">
              <button
                mat-menu-item
                [disabled]="document.status !== 'submitted'"
                (click)="verifyDocument(document.id)"
              >
                <span class="flex items-center">
                  <span>Verify Document</span>
                </span>
              </button>
              <button mat-menu-item (click)="rejectDocument(document.id)">
                <span class="flex items-center">
                  <span class="flex items-center">
                    <span>Reject Document</span>
                  </span>
                </span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr
          class="shadow"
          mat-header-row
          *matHeaderRowDef="documentsTableColumns; sticky: true"
        ></tr>
        <tr
          class="h-18 hover:bg-gray-100 dark:hover:bg-hover"
          mat-row
          *matRowDef="let document; columns: documentsTableColumns"
        ></tr>
      </table>
    </div>
  </ng-container>

  <mat-paginator
    class="sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
    [ngClass]="{ 'pointer-events-none': isLoading }"
    [length]="documentsCount"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="true"
    [hidden]="documentsCount === 0"
  ></mat-paginator>

  <ng-template #noDocuments>
    <div
      *ngIf="!isLoading"
      class="w-full p-8 text-2xl font-semibold tracking-tight text-center"
    >
      No information has been requested yet
      <div class="text-red-500 text-base" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
    <div
      *ngIf="isLoading"
      class="w-full p-8 text-2xl font-semibold tracking-tight text-center"
    >
      Loading...
    </div>
  </ng-template>
</div>
