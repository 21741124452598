/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TerminalReaderSettings } from './terminalReaderSettings';


export interface TerminalReaderCreateFromVarSheetParams { 
    description?: string;
    id?: string;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    metadata?: { [key: string]: string; };
    payment_provider_id?: string;
    processor_store_ref_id?: string;
    processor_terminal_id?: string;
    processor_terminal_ref_id?: string;
    /**
     * The provider of the terminal reader
     */
    provider: TerminalReaderCreateFromVarSheetParams.ProviderEnum;
    /**
     * The serial number of the terminal reader
     */
    serial_number?: string;
    settings?: Array<TerminalReaderSettings>;
    status?: TerminalReaderCreateFromVarSheetParams.StatusEnum;
    store_number?: string;
    terminal_number?: string;
    tsys_terminal_id?: string;
    /**
     * The type of card reader
     */
    type: TerminalReaderCreateFromVarSheetParams.TypeEnum;
}
export namespace TerminalReaderCreateFromVarSheetParams {
    export type ProviderEnum = 'aevi' | 'handpoint' | 'valor';
    export const ProviderEnum = {
        AEVI: 'aevi' as ProviderEnum,
        HANDPOINT: 'handpoint' as ProviderEnum,
        VALOR: 'valor' as ProviderEnum
    };
    export type StatusEnum = 'created' | 'active' | 'enabled' | 'disabled';
    export const StatusEnum = {
        CREATED: 'created' as StatusEnum,
        ACTIVE: 'active' as StatusEnum,
        ENABLED: 'enabled' as StatusEnum,
        DISABLED: 'disabled' as StatusEnum
    };
    export type TypeEnum = 'vl_100' | 'vl_110';
    export const TypeEnum = {
        _100: 'vl_100' as TypeEnum,
        _110: 'vl_110' as TypeEnum
    };
}


