<fuse-card class="h-fit p-6" #fuseCard>
  <!-- Activity feed -->
  <div class="flex w-full max-w-3xl flex-col">
    <!-- Title -->
    <div class="text-2xl font-semibold tracking-tight text-left">
      {{ title }}
    </div>
    <mat-divider class="my-3"></mat-divider>

    <ng-container *ngIf="timelineActivities && timelineActivities.length">
      <div>
        <ol>
          <!-- Activities -->
          <ng-container
            *ngFor="
              let activity of timelineActivities;
              let i = index;
              let first = first;
              let last = last;
              trackBy: trackByFn
            "
          >
            <!-- Activity Detail -->
            <li class="relative flex py-3">
              <!-- Line -->
              <ng-container *ngIf="!last">
                <div
                  class="absolute top-11 left-5 -ml-px h-[2.75rem] w-0.5 bg-gray-300 dark:bg-gray-600"
                ></div>
              </ng-container>

              <div class="relative flex flex-auto">
                <!-- Icon -->
                <ng-container *ngIf="activity.icon">
                  <div
                    class="mr-4 flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-white"
                  >
                    <mat-icon
                      class="text-black icon-size-5"
                      [svgIcon]="activity.icon"
                    >
                    </mat-icon>
                  </div>
                </ng-container>

                <!-- SVG Icon -->
                <ng-container *ngIf="activity.svgIcon">
                  <div
                    class="mr-4 flex h-10 w-10 shrink-0 items-center justify-center rounded-full"
                  >
                    <mat-icon
                      class="text-black icon-size-5"
                      [svgIcon]="activity.svgIcon"
                    >
                    </mat-icon>
                  </div>
                </ng-container>

                <!-- Content -->
                <div class="flex flex-auto flex-col items-start">
                  <!-- Title -->
                  <ng-container *ngIf="activity.title">
                    <div [innerHTML]="activity.title"></div>
                  </ng-container>
                  <ng-container *ngIf="activity.description">
                    <span class="text-gray-500 text-md">{{
                      activity.description
                    }}</span>
                  </ng-container>
                  <!-- ID and Date-->
                  <ng-container *ngIf="activity.id || activity.date">
                    <div
                      class="mt-2 flex flex-row text-sm leading-5 sm:mt-1 sm:flex-row sm:items-center sm:space-x-2"
                    >
                      <!-- Date -->
                      <div class="text-secondary">
                        {{ activity.date | date : 'medium' }}
                      </div>
                      <div *ngIf="!hideTransactionIds" class="text-secondary">
                        {{ activity.id }}
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </li>
          </ng-container>
        </ol>
      </div>
    </ng-container>
  </div>
</fuse-card>
